import { MutableRefObject, useState } from 'react';

export const useToggleInputValueVisible = (
  inputRef: MutableRefObject<unknown>
) => {
  const [isValueVisible, setIsValueVisible] = useState(false);

  const toggleValueVisibility = () => {
    setIsValueVisible(!isValueVisible);

    if (inputRef?.current) {
      setTimeout(() => {
        const { length } = inputRef.current.value;
        inputRef.current.focus();
        inputRef.current.setSelectionRange(length, length);
      }, 0);
    }
  };

  return { isValueVisible, setIsValueVisible, toggleValueVisibility };
};
