import styled, { css } from 'styled-components';
import { Typography } from '../Typography';
import { EllipsisText } from '../../../styled/common';

const getButtonTextColor = (button, colors, state, disabled) =>
  disabled && state === 'link' ? colors.text.secondary : button[state].color;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  text-align: center;
  padding: 10px 15px;
  white-space: nowrap;
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  ${({ theme, $isSmall, state, disabled }) => {
    const { borderRadius, fontFamily, button, colors } = theme;
    const width = $isSmall ? 'max-content' : '100%';
    const color = getButtonTextColor(button, colors, state, disabled);
    return { ...button[state], width, borderRadius, fontFamily, color };
  }}

  &:disabled {
    ${({ state }) => state !== 'link' && { backgroundColor: '#a5c5fc' }}
  }

  &:hover {
    ${({ hover }) => hover || 'inherit'};
  }

  ${({ styles }) => styles}
`;

export const ButtonText = styled(Typography)`
  ${EllipsisText};
  ${({ theme: { button, colors }, state, disabled }) => {
    const color = getButtonTextColor(button, colors, state, disabled);
    return { ...button[state], color };
  }};
  background: inherit;
`;

export const btnFixedAtBottomStyles = styles => css`
  width: calc(100% - 30px);
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  margin: 15px 15px 20px;
  flex-grow: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  ${styles}
`;
